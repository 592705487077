import React from 'react'
import { FormattedMessage } from 'react-intl';
import { closeCurrentPopin, displayPopin } from '../../../Hook/popins';
import Button from '../../Input/Button';

const mapErrorLink: any = {
    public_chat_subscription_required: {
        message: "chaterror__label__link_payment",
        popin: "subscribe"
    },
    paid_message_not_enough_tokens: {
        message: "chaterror__label__link_payment",
        popin: "subscribe"
    },
    not_enough_tokens: {
        message: "chaterror__label__link_payment",
        popin: "subscribe"
    },
    paid_website_subscription_needed: {
        message: "chaterror__label__link_payment",
        popin: "subscribe"
    },
    to_certified_subscription_needed: {
        message: "chaterror__label__link_payment",
        popin: "subscribe"
    },
    angel_message_not_enough_tokens: {
        message: "chaterror__label__link_payment__tokens",
        popin: "tokens"
    },
    restriction_chat_certification_required: {
        message: "chaterror__label__link__certification",
        popin: "certificate"
    },
    public_chat_certification_required: {
        message: "chaterror__label__link__certification",
        popin: "certificate"
    },
    to_certified_certification_needed: {
        message: "chaterror__label__link__certification",
        popin: "certificate"
    },
    email_address_confirmation_needed: {
        message: "chaterror__label__link__email_confirmation",
        popin: "emailconfirm"
    }
}

function ErrorMessage({ error, member }: { error: string, member?: any }) {
    const errorObject = mapErrorLink[error];
    return (
        <div className='flex gap-4 text-xs'>
            <div className='flex-1'>
                <div className='flex w-full mb-2 justify-center text-primary font-bold'>
                    <div>Admin</div>
                </div>
                <div onClick={() => {
                    if (!errorObject) return null;
                    if (errorObject.popin){
                        closeCurrentPopin();
                        displayPopin()(errorObject.popin);
                    }

                }} className="text-black mb-4 relative whitespace-prewrap w-full bg-primary px-4 py-2 rounded-xl">
                    <div className='text-black mb-4 text-center'><FormattedMessage values={{ member: member?.pseudonym }} id={"chaterror__label__" + error} /></div>
                    {errorObject?.popin === "subscribe" && <Button className={"text-primary font-bold bg-[#D5D7D8]"} appearance='outline' color='white' onClick={() => {}} name="menu__subscribe"></Button>}
                    {errorObject?.popin === "tokens" && <Button className={"text-primary font-bold bg-[#D5D7D8]"} appearance='outline' color='white' onClick={() => {}} name="mytokens__button_buy"></Button>}
                    {errorObject?.popin === "emailconfirm" && <Button className={"text-primary font-bold bg-[#D5D7D8]"} appearance='outline' color='white' onClick={() => {}} name="whyconfirm__goto_confirm"></Button>}
                    {errorObject?.popin === "certificate" && <Button className={"text-primary font-bold bg-[#D5D7D8]"} appearance='outline' color='white' onClick={() => {}} name="certification__title_certificate"></Button>}

                </div>
            </div>
        </div>
    )
}

export default ErrorMessage